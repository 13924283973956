<template>
    <section class="subscriptions">
      <div v-if="data && data.total_downgrade" class="acm">
        <img src="@/assets/images/icons/ic_info_navtt.svg" alt="information" width="21" class="mr-2">
        <span>If you want to decrease team size, you'll need to choose max. {{ data.total_downgrade }} active members <br> in your Community. <router-link :to="{ name: 'Decrease-Member' }">Learn More</router-link></span>
      </div>
      <div class="my-5 h-100">
        <div class="container-fluid h-100">
          <div v-if="loading" class="row d-flex align-items-center justify-content-center text-left text-white h-100 pb-5">
            <div class="col-5">
              <div class="h-100">
                <div class="card__wrapper mx-4">
                  <div class="card">
                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                    <div class="mt-3 mb-4">
                      <b-skeleton animation="wave" width="100%"></b-skeleton>
                    </div>
                    <div class="border-payment">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3 mt-4">
                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-4">
                        <b-skeleton animation="wave" width="100%"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row d-flex align-items-center justify-content-center text-left text-white h-100 pb-5">
            <div class="col-6">
              <div class="ml-5 h-100">
                <div class="card__wrapper mx-4">
                  <div class="card">
                    <!-- <h3 class="font-weight-normal">{{ data.package_name }} Subscription</h3> -->
                    <h3 class="font-weight-normal">{{ data.swc_request ? 'Top up SWC' : data.package_name }}</h3>
                    <div class="d-flex align-items-center mt-2 mb-4">
                      <img v-if="data.swc_request" src="@/assets/images/sidebar/sb_swc.png" alt="SWC" class="img-fluid">
                      <h1 class="text-bigger"><span class="font-weight-normal">IDR </span>{{ formatPrice(data.price) }} <span style="font-size: 18px; color: #8C8C8C">{{ data.package_bill == 'annual' ? $t('per-year') : $t('per-month') }}</span></h1>
                    </div>

                    <button class="btn p-0 btn--dropdown d-flex align-items-center mb-2" @click="visible = !visible">Service 
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none" class="ml-1 arrow--pc" :class="{'show': visible}">
                        <path d="M1 1L6 6L11 1" stroke="white" stroke-linecap="round"/>
                      </svg>
                    </button>

                    <b-collapse id="collapse-1" v-model="visible" class="mt-2 mb-3">
                          <div class="pr--details row px-3">
                            
                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{data.audio_multilingual_duration || "-"}}{{ $t('minutes') }} multi voice</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" v-if="data.voice_model_multilingual != 0">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ data.voice_model_multilingual || "-" }} {{ $t('voice-clone') }}</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" v-if="data.audio_duration != 0">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ data.audio_duration || "-" }}{{ $t('minutes') }} mono voice</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ $t('unlimited-ai-script-writer') }}</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ data.voice_characters || "-" }} {{ $t('voice-characters') }}</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ $t('unlimited-projects-music-video') }}</span>
                              </div>

                            </div>
                    </b-collapse>

                    <div class="border-payment">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <h4 class="font-weight-normal">{{ $t("bill") }}</h4>
                        <h4><span class="font-weight-normal">IDR </span> {{ formatPrice(data.price) }}</h4>
                      </div>
                      <div class="dashed--border mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-3" v-if="data.voucher_show">
                          <button class="btn p-0 btn-text--purple" v-if="!add_voucher" @click="add_voucher = !add_voucher">Add Voucher</button>
                          <div class="col-10" v-if="add_voucher || is_apply">
                              <div class="pr--input">
                                <input type="text" class="form-control input--voucher mb-2" :disabled="load_voucher" :placeholder="$t('input-voucher-code')" v-model="voucher" @keyup.enter="applyVoucher()"/>
                                <b-icon @click="cancelVoucher()" class="clear--voucher" :class="{'show': voucher.length > 0}" icon="x"></b-icon>
                              </div>
                              <span v-if="is_apply" class="success--apply">Applied successfully</span>
                              <span class="not-valid--apply" v-if="status_voucher_err && status_code === 400">{{ $t('voucher-400') }}</span>
                              <span class="not-valid--apply" v-else-if="status_voucher_err && status_code === 401">{{$t('voucher-401')}}</span>
                              <span class="not-valid--apply" v-else-if="status_voucher_err && status_code === 402">{{$t('voucher-402')}}</span>
                              <span class="not-valid--apply" v-else-if="status_voucher_err && status_code === 403">{{$t('voucher-403')}}</span>
                          </div>
                          <div class="col-2" v-if="add_voucher && !is_apply">
                              <button class="btn p-0 btn-text--purple ml-3" :class="{'mb-4': status_voucher_err}" :disabled="load_voucher || voucher.length < 1" @click="applyVoucher()">Apply</button>
                          </div>
                          <div class="col-2" v-if="add_voucher && is_apply">
                              <button class="btn p-0 btn-text--secondary ml-3" @click="cancelVoucher()" :class="{'mb-4': is_apply}">Cancel</button>
                          </div>
                        </div>
  
                        <div class="d-flex justify-content-between align-items-center" v-if="is_apply">
                          <div class="d-flex align-items-center" v-if="discount_price != null || discount_percentage != null">
                              <h4 class="font-weight-normal mb-0 mr-2">{{$t('discount')}}</h4>
                              <span class="badge--discount">{{ voucher_name }}</span>
                              <span class="badge--discount-percen ml-2" v-if="discount_percentage">{{ discount_percentage }}% Off</span>
                          </div>
                          <div class="d-flex align-items-center mb-3" v-else>
                              <h4 class="font-weight-normal mb-0 mr-2">Bonus</h4>
                              <span class="badge--discount">{{ voucher_name }}</span>
                          </div>
                          <h4><span class="font-weight-normal" v-if="discount_price">- IDR </span> {{ formatPrice(discount_price) }}</h4>
                        </div>

                        <div class="d-flex flex-column align-items-start mb-3" v-if="is_apply">
                          <span v-if="audio_multilingual_duration != 0">{{ audio_multilingual_duration }}{{ $t("mins") }} Multilingual Audio</span>
                          <span v-if="audio_duration != 0">{{ audio_duration }}{{ $t("mins") }} Monolingual Audio</span>
                          <span v-if="voice_model_multilingual != 0">{{ voice_model_multilingual }} Multilingual Voice Clone</span>
                          <span v-if="voice_model_monolingual != 0">{{ voice_model_monolingual }} Monolingual Voice Clone</span>
                          <span v-if="images_generates != 0">{{ images_generates }} Images</span>
                          <span v-if="swc != 0">{{ swc }} SWC</span>
                          <span v-if="storage_alloc != 0">{{ storage_alloc }}MB Asset Storage</span>
                        </div>
                      </div>
                      


                      <div class="d-flex justify-content-between align-items-center mb-3" v-if="data.discount_percentage && data.discount">
                        <h4 class="font-weight-normal">{{ $t('discount') }} {{ data.discount_percentage + '%' }}</h4>
                        <h4><span class="font-weight-normal">IDR </span> {{ "-"+formatPrice(data.discount) }}</h4>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h4 class="font-weight-bold">{{ $t('sub-total') }}</h4>
                        <!-- <h4 class="font-weight-normal">Tax<img src="@/assets/images/icons/ic_info.svg" alt="Info" class="img-fluid ml-2" v-b-tooltip.hover.bottomright="'This is tax and services'"></h4> -->
                        <h4><span class="font-weight-normal">IDR </span> {{ discount_price ? formatPrice(sub_total ) : sub_total  }}</h4>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-4">
                        <span class="font-weight-normal tax-title">{{ $t('tax') }}</span>
                        <!-- <h4 class="font-weight-normal">Tax<img src="@/assets/images/icons/ic_info.svg" alt="Info" class="img-fluid ml-2" v-b-tooltip.hover.bottomright="'This is tax and services'"></h4> -->
                        <h4><span class="font-weight-normal">IDR </span> {{ discount_price ? formatPrice(0.11 * (data.price - discount_price)) : formatPrice(data.price * 0.11)  }}</h4>
                      </div>

                      <div class="d-flex justify-content-between align-items-center mb-3" v-if="data.referral_status">
                        <div class="d-flex align-items-center">
                            <h4 class="font-weight-normal mb-0 mr-2">Discount</h4>
                            <span class="badge--discount">Referral</span>
                            <span class="badge--discount-percen ml-2" v-if="data.discount_referral_percentage">{{ data.discount_referral_percentage }}% Off</span>
                        </div>
                        <h4><span class="font-weight-normal" v-if="data.discount_referral">- IDR </span> {{ formatPrice(data.discount_referral) }}</h4>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3" v-if="!data.referral_status">
                      <h4 class="font-weight-normal">TOTAL</h4>
                      <h4 style="font-size: 24px"><span class="font-weight-normal">IDR </span> {{ discount_price ? formatPrice(sub_total + 0.11 * (data.price - discount_price)) :  formatPrice(data.price + data.tax)}}</h4>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-3" v-else>
                      <h4 class="font-weight-normal">TOTAL</h4>
                      <h4 style="font-size: 24px"><span class="font-weight-normal">IDR </span> {{ formatPrice(data.price + data.tax - data.discount_referral) }}</h4>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mt-4">
                      <button class="btn w-100 btn-purple" :disabled="load_voucher" @click="createInvoice()">Pay Now</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorCreateInvoices />
    </section>
  </template>
  <script>
  import $ from "jquery";
  import { getDetailPayment, searchVoucher } from "@/services/payment/payment.service.js"
  import { topupSwc } from '@/services/subscriptions/subscriptions.service'

  import ErrorCreateInvoices from "@/components/modal/ErrorCreateInvoice";
  
  export default {
    components: {
      ErrorCreateInvoices
    },
    data() {
      return {
        data: {

        },
        loading : false,
        add_voucher: false,
        is_apply: false,
        visible: false,
        voucher: "",
        voucher_name: null,
        load_voucher: false,
        status_voucher_err:false,
        discount_percentage: null,
        discount_price: null,
        audio_duration: 0,
        audio_multilingual_duration: 10,
        images_generates: 0,
        storage_alloc: 0,
        swc: 0,
        voice_model_monolingual: 0,
        voice_model_multilingual: 0,
        load_topup: false,
        voucher_id: null,
        status_code: null
      };
    },
  
    mounted() {
      $("section.subscriptions").parent().addClass("mx-2");
      this.getData()
    },

    watch: {
      voucher(){
        this.is_apply = false;
        this.status_voucher_err = false;
        this.status_code = null;
        this.load_voucher = false;
        this.discount_price = null;
        this.discount_percentage = null
      }
    },

    computed: {
      sub_total(){
        return this.discount_price ? this.data.price - this.discount_price : this.formatPrice(this.data.price)
      }
    },
  
    methods: {
      createInvoice(){
        this.load_topup = true;

        let formData = {
          package_id : this.$route.query.packageId,
          bill_type : this.$route.query.packageBill,
          voucher_id: this.voucher_id ? this.voucher_id : null
        }

        topupSwc(formData).then((response) => {
          if(response.status == true){
            this.load_topup = false;
            this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id, packageId: this.$route.query.packageId, packageBill: this.$route.query.packageBill } })
          }else{
            this.$bvModal.show('err-create__invoices');
          }
        })
        .catch((err) => {
          this.load_topup = false;
          this.$bvModal.show('err-create__invoices');
          console.log(err)
        })
      },
        cancelVoucher(){
            this.add_voucher = true;
            this.is_apply = false;
            this.status_voucher_err = false;
            this.status_code = null;
            this.load_voucher = false;
            this.discount_price = null;
            this.discount_percentage = null;
            this.voucher =  "";
            this.voucher_id =  null;
        },

        getData(){
          this.loading = true;
          getDetailPayment(this.$route.query.packageId, this.$route.query.packageBill).then((res) =>{
            if(res.status){
              this.data = res.data;
              this.loading = false;
            }
          }).catch((err)=>{
            console.log(err)
            this.loading = false;
          })
        },
      applyVoucher(){
        this.add_voucher = true;
        
        this.load_voucher = true;
        let data =  {
          package_id: this.$route.query.packageId,
          package_bill: this.$route.query.packageBill,
          voucher: this.voucher
        }
        searchVoucher(data).then((res) =>{
          if(res.status){
            this.is_apply = true;
            this.status_voucher_err = false;
            this.status_code = null
            this.voucher_name = res.data.voucher_name || null;
            this.discount_price = res.data.discount_price || null;
            this.discount_percentage = res.data.discount_percentage || null;
            this.voucher_id = res.data.voucher_id || null;


            this.audio_duration = res.data.audio_duration || 0;
            this.audio_multilingual_duration = res.data.audio_multilingual_duration || 0;
            this.images_generates = res.data.images_generates || 0;
            this.storage_alloc = res.data.storage_alloc || 0;
            this.swc = res.data.swc || 0;
            this.voice_model_monolingual = res.data.voice_model_monolingual || 0;
            this.voice_model_multilingual = res.data.voice_model_multilingual || 0;


            this.load_voucher = false;
          }else{
            this.voucher_id = null
            this.status_voucher_err = true;
            this.status_code = res.code
            this.load_voucher = false;
          }
        }).catch((err) =>{
          this.load_voucher = false;
          console.log(err);
        })
      },
  
      formatPrice(price) {
        if(price) {
          return `${price.toLocaleString()}`;
        } else {
          return null
        }
        // return price;
      },
    },
  
  
  };
  </script>
  
  <style scoped>
  .clear--voucher.show{
    opacity: 1;
  }
  .clear--voucher{
    position: absolute;
    right: 6px;
    top: 10px;
    cursor: pointer;
    opacity: 0;
    transition: .25s all ease-in;
  }
  .pr--input{
    position: relative;
  }
  .dashed--border{
    border-bottom: 0.1px dashed #383838;
    padding-bottom: 1rem;
  }
  .tax-title{
    font-size: 18px;
  }
  .btn--dropdown{
    color: #fff;
    font-size: 18px;
  }
  .arrow--pc.show{
      transform: rotate(-178deg);
      transition: .25s all ease;
  }
  .arrow--pc{
      transform: rotate(0deg);
      transition: .25s all ease;
  }
  .success--apply{
    color: #369D06;
    font-weight: 600;
    font-size: 14px;
  }
  .not-valid--apply{
    color: #dc3545;
    font-weight: 600;
    font-size: 14px;
  }
  
  .input--voucher{
    background-color: transparent;
    border: 1px solid #585859;
    color: #fff;
  }
  .badge--discount{
    color: #6D75F6;
    padding: 2px 11px;
    background-color: rgba(109, 117, 246, 0.2);
    border-radius: 12.5px;
    border: 1px solid #6D75F6;
    width: 80px;
    white-space: nowrap;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .badge--discount-percen{
    color: #C4C4C4;
    padding: 2px 11px;
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 12.5px;
    border: 1px solid #C4C4C4;
    width: 80px;
    white-space: nowrap;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .btn-purple{
        color: white;
        min-height: 40px;
        background-color: #6D75F6;
        font-size: 20px;
        font-weight: 500;
        border: 1px solid #6D75F6
    }
  .btn-text--purple:disabled{
    color: #585859;

  }
  .btn-text--purple{
        color: #6D75F6;
        font-weight: 600;
    }
  .btn-text--secondary:hover{
      color: #fff;
      transition: all .25s;
  }
  .btn-text--secondary{
        color: #585859;
        font-weight: 600;
        transition: all .25s;
    }
    .subscriptions {
      padding: 90px 80px 40px 80px;
      height: 98vh;
    }
  
    .text-bigger {
      font-size: 40px;
    }
  
    .sub-text {
      font-size: 24px !important;
    }
  
  
    .border-payment {
      border-bottom: 0.1px dashed #383838;
      border-top: 0.1px solid #383838;
      padding-top: 2rem;
    }
  
    iframe {
      height: 100%;
    }
  
    .acm {
      padding: 10px 30px;
      background: rgba(109, 117, 246, 0.2);
      width: fit-content;
      margin: auto;
    }
  
    .acm span {
      color: white;
      font-weight: 600;
      font-size: 20px;
    }
  
    .acm span a {
      color: #6D75F6;
      text-decoration: underline;
    }
  
    .card__wrapper {
      padding: 2px;
        background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
        border-radius: 8px;
    }
  
    .card {
      padding: 32px;
      background-color: #121418;
      border-radius: 6px;
      backdrop-filter: blur(4px);
    }
  </style>
  